const usePopupStyles = (theme) => ({
  root: {
    position: "absolute",
    zIndex: 1300,
    borderRadius: "10px",
    padding: "0px",
    boxShadow: "0px 4px 10px 0px #00000029",
    overflow: "hidden",
  },
  papper: {
    boxShadow: "none",
    width: "300px",
    maxHeight: "300px",
    overflow: "auto",
    padding: "8px",
    margin: "0px",
    borderRadius: "10px",
    backgroundColor: theme?.palette?.editor?.background,
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& button": {
      color: theme?.palette?.editor?.textColor,
    },
  },
  groupHeader: {
    padding: "10px 8px",
    marginBottom: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "1px solid #DCE4EC",
  },
  listItem: {
    cursor: "pointer",
    background: "transparent",
    borderRadius: "10px",
    border: `1px solid transparent`,
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "&.active": {
      background: `${theme?.palette?.containers?.bg3 || "#E9F3FE"}`,
    },
    "&:hover": {
      background: `${theme?.palette?.containers?.bg3 || "#E9F3FE"}`,
    },
    "&.renderComp": {
      padding: "0px",
      "& button.cmd": {
        // to invalidate click event of toolbar icon
        // will trigger by forard ref
        pointerEvents: "none",
        width: "100%",
        padding: "4px",
        borderRadius: "0px",
        justifyContent: "start",
        "& svg": {
          margin: "8px",
          width: "24px",
          height: "24px",
        },
        span: {
          padding: "8px",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  cardMedia: {
    width: 24,
    height: 24,
    margin: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default usePopupStyles;
