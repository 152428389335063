import React from "react";
import ReactDOM from "react-dom/client";
import CommonEditor from "./components/Editor/CommonEditor";
import IframeEditor from "./components/Editor/IframeEditor";
// for analytics
import Analytics from "./Analytics";

const iframeContainer = document.getElementById("editor-iframe-root");

// for load editor in iframe
if (iframeContainer) {
  const iframeRoot = ReactDOM.createRoot(iframeContainer);
  iframeRoot.render(
    <React.StrictMode>
      <IframeEditor
        id={"-1"}
        content={window.pageData || []}
        readOnly={"readonly"}
        otherProps={{
          ICON_API: "https://assets.agenciflow.com" 
        }}
      />
    </React.StrictMode>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById("editor-root"));
  root.render(
    <React.StrictMode>
      <Analytics />
      <CommonEditor
        id={"pages"}
        domain={window.location.href}
        content={window.pageData || []}
        readOnly={"readonly"}
        otherProps={{
          ICON_API: "https://assets.agenciflow.com" 
        }}
      />
    </React.StrictMode>
  );
}
